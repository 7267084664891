import { useEffect, useState } from 'react';

export default function useTab(param) {
  const [tab, setTab] = useState('1');
  useEffect(() => {
    if (param) {
      switch (param.split('=').pop()) {
        case 'updates':
          setTab('1');
          break;
        case 'events':
          setTab('2');
          break;
        case 'articles':
          setTab('3');
          break;
        default:
          setTab('1');
      }
    }
  }, [param]);

  return tab;
}
