import React, { useCallback, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  Button
} from 'reactstrap';
import { useIntl } from 'gatsby-plugin-intl';

import {
  Layout,
  UpdatesList,
  TopArticlesList,
  EventsList,
  Image,
  SEO
} from '../components';
import searchIcon from '../assets/images/search-icon.png';
import useTab from '../utils/useTab';
import filterByLanguage from '../utils/filterByLangage';
import messages from '../messages/activities';
import useWindowDimensions from '../utils/useWindowDimensions';

const Activities = ({
  data: {
    allUpdates: { group: allUpdates },
    allEvents: { group: allEvents },
    allArticles: { group: allArticles }
  },
  location: { search: tabRedirection },
  pageContext: {
    intl: { language: currentLanguage }
  }
}) => {
  const intl = useIntl();

  const { width } = useWindowDimensions();
  const extraSmallLimit = 992;
  const [isExtraSmallScreen, setIsExtraSmallScreen] = useState(
    width < extraSmallLimit
  );

  useEffect(() => {
    setIsExtraSmallScreen(width < extraSmallLimit);
  }, [width]);

  const defaultTab = useTab(tabRedirection);
  const [updates, setUpdates] = useState([]);
  const [events, setEvents] = useState([]);
  const [topArticles, setTopArticles] = useState([]);

  const [searchInput, setSearchInput] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);

  const [activeTab, setActiveTab] = useState('1');
  const itemsPerPage = 6;

  const toggle = tab => {
    if (activeTab !== tab) {
      setCurrentPage(1);
      setActiveTab(tab);
    }
  };

  const paginate = useCallback(
    ({ array = [], perPage = itemsPerPage, constant = 0 }) => {
      return array.slice(0, currentPage * perPage + constant);
    },
    [currentPage, itemsPerPage]
  );

  const search = useCallback(
    (input = searchInput) => {
      let searchResult = [];
      if (activeTab === '1') {
        searchResult = filterByLanguage(
          allUpdates,
          currentLanguage
        ).filter(({ node: post }) =>
          post.title.toLowerCase().includes(input.toLowerCase())
        );
        setUpdates(paginate({ array: searchResult, constant: 1 }));
      } else if (activeTab === '2') {
        searchResult = filterByLanguage(
          allEvents,
          currentLanguage
        ).filter(({ node: event }) =>
          event.title.toLowerCase().includes(input.toLowerCase())
        );
        setEvents(paginate({ array: searchResult, constant: 3 }));
      } else {
        searchResult = filterByLanguage(
          allArticles,
          currentLanguage
        ).filter(({ node: article }) =>
          article.title.toLowerCase().includes(input.toLowerCase())
        );
        setTopArticles(paginate({ array: searchResult }));
      }
      setNumberOfPages(Math.ceil(searchResult.length / itemsPerPage));
    },
    [searchInput, currentPage, activeTab]
  );

  useEffect(() => {
    search();
  }, [currentPage, activeTab]);

  useEffect(() => {
    setActiveTab(defaultTab);
  }, [defaultTab]);
  return (
    <Layout>
      <SEO title={intl.formatMessage(messages.activities)} />
      <Row>
        <Col className="mt-5">
          <div className="d-flex justify-content-between flex-wrap mb-5 activities-tabs-and-search">
            <Nav tabs className="activities-tabs">
              <NavItem>
                <NavLink
                  active={activeTab === '1'}
                  onClick={() => {
                    toggle('1');
                  }}
                  className="pb-3 activities-single-tab"
                >
                  {intl.formatMessage(messages.updates)}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={activeTab === '2'}
                  onClick={() => {
                    toggle('2');
                  }}
                  className="pb-3 activities-single-tab"
                >
                  {intl.formatMessage(messages.events)}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={activeTab === '3'}
                  onClick={() => {
                    toggle('3');
                  }}
                  className="pb-3 activities-single-tab"
                >
                  {intl.formatMessage(messages.topArticles)}
                </NavLink>
              </NavItem>
            </Nav>
            <InputGroup
              className={`activities-search-box ${
                isExtraSmallScreen ? 'my-3' : ''
              }`}
            >
              <InputGroupAddon addonType="prepend" className="search-icon">
                <Image src={searchIcon} alt="Hivos logo" width={18} />
              </InputGroupAddon>
              <Input
                type="text"
                value={searchInput}
                onChange={e => {
                  setCurrentPage(1);
                  setSearchInput(e.target.value);
                  search(e.target.value);
                }}
                placeholder="search ..."
                className="search-field"
              />
            </InputGroup>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <UpdatesList updates={updates} />
            </TabPane>
            <TabPane tabId="2">
              <EventsList events={events} />
            </TabPane>
            <TabPane tabId="3">
              <TopArticlesList articles={topArticles} />
            </TabPane>
          </TabContent>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="d-flex justify-content-center">
            {!(numberOfPages <= currentPage) && (
              <Button
                disabled={numberOfPages <= currentPage}
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                }}
              >
                {intl.formatMessage(messages.loadMore)}
              </Button>
            )}
          </div>
        </Col>
      </Row>
    </Layout>
  );
};
export default Activities;

export const query = graphql`
  {
    allUpdates: allWpPost(
      filter: { author: { node: { slug: { eq: "admin" } } } }
      sort: { fields: [date, language___slug], order: DESC }
    ) {
      group(field: language___slug) {
        edges {
          node {
            id
            slug
            title
            content
            dateAgoFr: date(fromNow: true, locale: "fr")
            dateAgoEn: date(fromNow: true, locale: "en")
            date(formatString: "D/MM/YYYY")
            excerpt
            tags {
              nodes {
                id
                slug
                name
              }
            }
            categories {
              nodes {
                id
                slug
                name
              }
            }
            author {
              node {
                id
                slug
                name
                avatar {
                  url
                }
              }
            }
            featuredImage {
              node {
                id
                sourceUrl
                localFile {
                  publicURL
                }
              }
            }
            language {
              name
              slug
            }
          }
        }
      }
    }

    allEvents: allWpEvent(
      sort: { fields: [date, language___slug], order: DESC }
    ) {
      group(field: language___slug) {
        edges {
          node {
            id
            content
            title
            slug
            dateAgoFr: date(fromNow: true, locale: "fr")
            dateAgoEn: date(fromNow: true, locale: "en")
            date(formatString: "D/MM/YYYY")
            day: date(formatString: "DD")
            month: date(formatString: "MM")
            excerpt
            tags {
              nodes {
                id
                slug
                name
              }
            }
            categories {
              nodes {
                id
                slug
                name
              }
            }
            author {
              node {
                id
                slug
                name
                avatar {
                  url
                }
              }
            }
            featuredImage {
              node {
                id
                sourceUrl
                localFile {
                  publicURL
                }
              }
            }
            language {
              name
              slug
            }
          }
        }
      }
    }

    allArticles: allWpPost(
      filter: { author: { node: { slug: { ne: "admin" } } } }
      sort: { fields: [date, language___slug], order: DESC }
    ) {
      group(field: language___slug) {
        edges {
          node {
            id
            slug
            title
            content
            dateAgoFr: date(fromNow: true, locale: "fr")
            dateAgoEn: date(fromNow: true, locale: "en")
            date(formatString: "D/MM/YYYY")
            excerpt
            tags {
              nodes {
                id
                slug
                name
              }
            }
            categories {
              nodes {
                id
                slug
                name
              }
            }
            author {
              node {
                id
                slug
                name
                avatar {
                  url
                }
              }
            }
            featuredImage {
              node {
                id
                sourceUrl
                localFile {
                  publicURL
                }
              }
            }
            language {
              name
              slug
            }
          }
        }
      }
    }
  }
`;
